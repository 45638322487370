import React, { useState, useEffect } from "react";
import "react-dropdown/style.css";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";
import ContentLoader from "react-content-loader";

import { StyledContainer, StyledRoyalty } from "../../styled";
import { connect } from "../../redux/blockchain/blockchainActions";
import abbreviate from "../../helpers/abbreviate";
import images from "../../assets/images";

function Rarity() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [totalNftMinted, setTotalNftMinted] = useState(10000);
  const [annual, setAnnual] = useState(
    "360 days 22 hours 03 minutes remaining"
  );
  const [data, setData] = useState({
    pool: 0,
    perpunk: 0,
    pp: 0,
    share: 0,
    numberClaim: 0,
    decimal: 18,
    loading: true,
  });

  const options = [
    {
      icon: images.pulseIcon,
      label: "PLS",
      value: "pls",
    },
    {
      icon: images.pulseIconWhite,
      label: "WPLS",
      value: "wpls",
      address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
      decimal: 18,
    },
    {
      icon: images.DAIicon,
      label: "DAI",
      value: "dai",
      address: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
      decimal: 18,
    },
    {
      icon: images.USDCicon,
      label: "USDC",
      value: "usdc",
      address: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
      decimal: 6,
    },
    {
      icon: images.USDTicon,
      label: "USDT",
      value: "usdt",
      address: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
      decimal: 6,
    },
    {
      icon: images.HEXicon,
      label: "pHEX",
      value: "phex",
      address: "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
      decimal: 8,
    },
    {
      icon: images.HEXicon,
      label: "eHEX",
      value: "ehex",
      address: "0x57fde0a71132198BBeC939B98976993d8D89D225",
      decimal: 8,
    },
    {
      icon: images.PLSXicon,
      label: "PLSX",
      value: "plsx",
      address: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
      decimal: 18,
    },
    {
      icon: images.INCicon,
      label: "INC",
      value: "inc",
      address: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "HOA",
      value: "hoa",
      address: "0x7901a3569679AEc3501dbeC59399F327854a70fe",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "Spurdo",
      value: "spurdo",
      address: "0xfeAad42f44527Af1a8b5A2b49393984707a1ee45",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "GLASS",
      value: "glass",
      address: "0xd687FF2C9C5294f4A2bC0300B46eA921dB312063",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "LEMON",
      value: "lemon",
      address: "0xfA23FB624036D523f9Aa419c10B47a645Bd8FBF4",
      decimal: 9,
    },
    {
      icon: images.genericicon,
      label: "K00LAID",
      value: "k00laid",
      address: "0x739f7B840F930366010202593058dae15c233A79",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "SYTM",
      value: "sytm",
      address: "0x9Bda574060C2Eb409c0474CBBD9A0F8D0Cec1470",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "Sniper Bakla",
      value: "pinoy",
      address: "0xE8E40659805D8c5c14322dEcBe869b3bcb72d655",
      decimal: 9,
    },
    {
      icon: images.genericicon,
      label: "HTR",
      value: "htr",
      address: "0x11b84e2cc863D1EfA27DBa8864A91db557982942",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "ORGN",
      value: "orgn",
      address: "0x160Aaea031006C125F8Be4A00f77AC5010BECB3c",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "TWERK",
      value: "twerk",
      address: "0xe11a9e0298fBB1248611956db3C8FF556DC1DdbD",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "TIMMY",
      value: "timmy",
      address: "0x827DB5c46b3CC1B6488eB6040aF735f464B47eea",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "NightDoge",
      value: "nightdoge",
      address: "0x8307E7e27035b1B63b86cF6A006f77C0f91BF9a4",
      decimal: 18,
    },
    {
      icon: images.genericicon,
      label: "MINT",
      value: "min",
      address: "0x207e6b4529840A4fd518f73c68bc9c19B2A15944",
      decimal: 18,
    }
  ];

  const [selectedSort, setSelectedSort] = useState(options[0]);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    MARKET_ADDRESS: "",
    ROYALTY_ADDRESS: "0xB3B4D613f6fDBc81D1149A3A82DB842e80BBe58A",
    SCAN_LINK: "",
    API: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MAX_ITEM_PER_MINT: 0,
    MAX_ITEM_PER_MINT_NON_VIP: 0,
    OWNED_LINK: "",
  });

  useEffect(() => {
    getConfig();
  }, []);
``
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const Option = ({ children, ...props }) => {
    return (
      <components.Option className="custom-option" {...props}>
        <img src={props.data.icon} alt="" />
        {children}
      </components.Option>
    );
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        <img src={props.data.icon} alt="" />
        {children}
      </components.SingleValue>
    );
  };

  const getData = async () => {
    if (blockchain?.account) {
      const totalSupply = await blockchain.smartContract.methods
        .totalSupply()
        .call();
      setTotalNftMinted(totalSupply);
      const myBalance = await blockchain.smartContract.methods
        .balanceOf(blockchain.account)
        .call();

      setData({
        ...data,
        loading: true,
      });
      let pool = 0;
      let poolInNumber;
      let poolPerPunk;
      let poolShare;
      let claimTimes;
      let nextDrawingTime;
      let remainingTime;

      if (selectedSort.value === "pls") {
        pool = await blockchain.web3.eth.getBalance(CONFIG.ROYALTY_ADDRESS);
        poolInNumber = (+blockchain.web3.utils.fromWei(
          pool.toString(),
          "ether"
        )).toFixed(2);

        claimTimes = await blockchain.royaltyContract.methods
          .timesDraw()
          .call();

        nextDrawingTime = await blockchain.royaltyContract.methods
          .nextDrawTime()
          .call();
        remainingTime = secondsToDhms(
          +nextDrawingTime - Math.floor(Date.now() / 1000)
        );
      } else {
        const erc20Abi = await fetch("/config/erc20.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const erc20AbiJson = await erc20Abi.json();
        Web3EthContract.setProvider(blockchain.provider);
        const tokenContract = new Web3EthContract(
          erc20AbiJson,
          selectedSort.address
        );
        pool = await tokenContract.methods
          .balanceOf(CONFIG.ROYALTY_ADDRESS)
          .call();
        // poolInNumber = (+blockchain.web3.utils.fromWei(
        //   pool.toString(),
        //   "nano"
        // ));
        poolInNumber = pool / 10 ** selectedSort.decimal;
        claimTimes = await blockchain.royaltyContract.methods
          .tokenTimesDraw(selectedSort.address) // previously .tokenTimesDraw(contractAddress)
          .call();

        nextDrawingTime = await blockchain.royaltyContract.methods
          .tokenNextDrawTime(selectedSort.address)
          .call();
        remainingTime = secondsToDhms(
          +nextDrawingTime - Math.floor(Date.now() / 1000)
        );
      }

      poolPerPunk = +poolInNumber / totalSupply;
      poolShare = +poolInNumber * (+myBalance / +totalSupply);

      setAnnual(remainingTime || "Claimable");
      setData({
        ...data,
        pool: poolInNumber,
        perpunk: poolPerPunk,
        pp: +myBalance,
        share: poolShare || 0,
        numberClaim: claimTimes,
        loading: false,
      });
    } else {
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const nftAbi = await fetch("/config/abi.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const royaltyAbi = await fetch("/config/royaltyDisperse.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const nftAbiJson = await nftAbi.json();
      const royaltyAbiJson = await royaltyAbi.json();
      const CONFIG = await configResponse.json();

      const web3 = new Web3(CONFIG.NETWORK.RPC_URL);
      Web3EthContract.setProvider(web3.currentProvider);

      const nftContract = new Web3EthContract(
        nftAbiJson,
        CONFIG.CONTRACT_ADDRESS
      );

      const royaltyContract = new Web3EthContract(
        royaltyAbiJson,
        CONFIG.ROYALTY_ADDRESS
      );

      const totalSupply = await nftContract.methods.totalSupply().call();
      setTotalNftMinted(totalSupply);

      setData({
        ...data,
        loading: true,
      });

      let pool = 0;
      let poolInNumber;
      let poolPerPunk;
      let claimTimes;
      let nextDrawingTime;
      let remainingTime;

      if (selectedSort.value === "pls") {
        pool = await web3.eth.getBalance(CONFIG.ROYALTY_ADDRESS);
        poolInNumber = web3.utils.fromWei(pool.toString(), "ether");

        claimTimes = await royaltyContract.methods.timesDraw().call();

        nextDrawingTime = await royaltyContract.methods.nextDrawTime().call();
        remainingTime = secondsToDhms(
          +nextDrawingTime - Math.floor(Date.now() / 1000)
        );
      } else {
        const erc20Abi = await fetch("/config/erc20.json", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const erc20AbiJson = await erc20Abi.json();

        const tokenContract = new Web3EthContract(
          erc20AbiJson,
          selectedSort.address
        );
        pool = await tokenContract.methods
          .balanceOf(CONFIG.ROYALTY_ADDRESS)
          .call();
        // poolInNumber = (+blockchain.web3.utils.fromWei(
        //   pool.toString(),
        //   "nano"
        // ));
        poolInNumber = pool / 10 ** selectedSort.decimal;
        claimTimes = await royaltyContract.methods
          .tokenTimesDraw(selectedSort.address) // previously .tokenTimesDraw(contractAddress)
          .call();

        nextDrawingTime = await royaltyContract.methods
          .tokenNextDrawTime(selectedSort.address)
          .call();

        remainingTime = secondsToDhms(
          +nextDrawingTime - Math.floor(Date.now() / 1000)
        );
      }

      poolPerPunk = +poolInNumber / totalSupply;

      setAnnual(remainingTime || "Claimable");
      setData({
        ...data,
        pool: poolInNumber,
        perpunk: poolPerPunk,
        pp: 0,
        share: 0,
        numberClaim: claimTimes,
        loading: false,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain?.account, selectedSort]);

  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const claimNative = () => {
    blockchain.royaltyContract.methods
      .disperse()
      .send({
        to: CONFIG.ROYALTY_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
      })
      .then((receipt) => {
        getData();
      });
  };

  const claimToken = () => {
    blockchain.royaltyContract.methods
      .tokenDisperse(selectedSort.address)
      .send({
        to: CONFIG.ROYALTY_ADDRESS,
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
      })
      .then((receipt) => {
        getData();
      });
  };

  const handleClaim = () => {
    if (selectedSort.value == "pls") {
      claimNative();
    } else {
      claimToken();
    }
  };

  return (
    <StyledContainer className="banner-wrapper shape-a">
      <StyledRoyalty selected={selectedSort.icon}>
        <div className="inner wow fadeInUpBig">
          {
            <Select
              defaultValue={selectedSort}
              onChange={setSelectedSort}
              options={options}
              className="select"
              classNamePrefix="select-inner"
              isSearchable={false}
              isMulti={false}
              components={{
                SingleValue,
                Option,
              }}
            />
          }

          <div className="dual">
            <div className="item">
              <p
                onClick={() =>
                  window.open(
                    `${CONFIG.OWNED_LINK}${CONFIG.ROYALTY_ADDRESS}`,
                    "_blank"
                  )
                }
                className="title-link"
              >
                ROYALTY POOL <img src={images.externalLink} alt="" />
              </p>
              {
                <p className="value">
                  <img src={selectedSort.icon} alt="" />{" "}
                  {data.loading ? (
                    <ContentLoader
                      speed={2}
                      width={20}
                      height={18}
                      viewBox="0 0 25 20"
                      backgroundColor="#7d7d7d"
                      foregroundColor="#ffffff"
                      style={{ marginRight: 4 }}
                    >
                      <rect x="1" y="-3" rx="0" ry="0" width="25" height="20" />
                    </ContentLoader>
                  ) : (
                    abbreviate(data.pool, 2)
                  )}
                </p>
              }
            </div>
            <div className="item">
              <p className="title">ROYALTY PER PUNK</p>
              {
                <p className="value">
                  <img src={selectedSort.icon} alt="" />
                  {data.loading ? (
                    <ContentLoader
                      speed={2}
                      width={20}
                      height={18}
                      viewBox="0 0 25 20"
                      backgroundColor="#7d7d7d"
                      foregroundColor="#ffffff"
                      style={{ marginRight: 0 }}
                    >
                      <rect x="1" y="-3" rx="0" ry="0" width="25" height="20" />
                    </ContentLoader>
                  ) : (
                    abbreviate(data.perpunk, 2)
                  )}
                </p>
              }
            </div>
          </div>
          <div className="dual">
            <div className="item">
              <p className="title">YOUR PULSEPUNKS</p>
              {!blockchain.account ? (
                <p className="value connect fontRegular">
                  Connect Wallet to View
                </p>
              ) : (
                <p className="value">
                  {" "}
                  {data.loading ? (
                    <ContentLoader
                      speed={2}
                      width={20}
                      height={18}
                      viewBox="0 0 25 20"
                      backgroundColor="#7d7d7d"
                      foregroundColor="#ffffff"
                      style={{ marginRight: 4 }}
                    >
                      <rect x="1" y="-3" rx="0" ry="0" width="25" height="20" />
                    </ContentLoader>
                  ) : (
                    data.pp
                  )}
                  <span className="fontRegular">/{totalNftMinted}</span>
                </p>
              )}
            </div>
            <div className="item">
              <p className="title">YOUR SHARE OF POOL</p>
              {!blockchain.account ? (
                <p className="value connect fontRegular">
                  Connect Wallet to View
                </p>
              ) : (
                <p className="value">
                  <img src={selectedSort.icon} alt="" />
                  {data.loading ? (
                    <ContentLoader
                      speed={2}
                      width={20}
                      height={18}
                      viewBox="0 0 25 20"
                      backgroundColor="#7d7d7d"
                      foregroundColor="#ffffff"
                      style={{ marginRight: 0 }}
                    >
                      <rect x="1" y="-3" rx="0" ry="0" width="25" height="20" />
                    </ContentLoader>
                  ) : (
                    abbreviate(data.share, 2)
                  )}
                </p>
              )}
            </div>
          </div>

          <div className="timer">
            <p className="grey fontRegular">
              To ensure decentralization, anyone from the community can trigger
              the distribution of the community royalty. The button will be
              available once a year after October 9.{" "}
            </p>
            <input
              value={data.loading ? "Loading data..." : annual}
              type="text"
              disabled
            />
          </div>

          <div className="timer">
            {blockchain.account && (
              <p className="fontRegular">
                {annual === "Claimable" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ margin: "0px" }}>
                      Community royalty will be distributed in batches of 1000
                      NFTs.
                    </p>
                    <p style={{ margin: "0px" }}>
                      Contract needs to be executed 10 times to complete the
                      distribution.
                    </p>
                  </div>
                ) : (
                  "Please wait until this year’s community royalty is ready to be distributed !"
                )}
              </p>
            )}
            {!blockchain.account ? (
              <button
                className="lab-btn claimBtn"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                }}
              >
                <img src={images.accountWhite} alt="" />
                CONNECT WALLET
              </button>
            ) : (
              <button
                className="lab-btn claimBtn"
                disabled={data.loading || annual !== "Claimable"}
                onClick={() => handleClaim()}
              >
                DISTRIBUTE COMMUNITY ROYALTY{" "}
                <span
                  style={{
                    color: annual === "Claimable" ? "white" : "#9d9d9d",
                  }}
                >
                  [{data.numberClaim}/10]
                </span>
              </button>
            )}
          </div>
        </div>
      </StyledRoyalty>
    </StyledContainer>
  );
}

export default Rarity;
